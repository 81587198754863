.footer {
  background-color:  #030213;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-conteudo {
  margin: 0 auto;
  max-width: 100;
}

.img-footer {
  width: 40px;
  height: 40px; 
  margin: 5px 15px;
}

.footer p {
  margin: 10px 0;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.imagens-footer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

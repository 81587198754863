.contato-container {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    background-color: #f9f9f9;
  }
  
  .contato-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  .contato-form input,
  .contato-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .contato-form input[type="radio"] {
    width: auto;
    margin-right: 5px;
  }
  
  .contato-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color:#0a0061;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contato-form button:hover {
    background-color: #0a0061;;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  